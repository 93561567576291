export enum IncidentStatusEnum {
    NewIncident = 'NewIncident',
    Open = 'Open',
    Closed = 'Closed',
    Removed = 'Removed',
    RemovalRequested = 'RemovalRequested',
}
export interface CitationIncidents {
    id: number;
    citationDocumentId: number | null;
    citationNumber?: string
    internalId?: number
}

export interface Incidents {
    id: number;
    reportDate: Date | string;
    incidentStatus: IncidentStatusEnum;
    location: string | null;
    assetTruck: string;
    assetTrailers: [];
    reportNumber: number | null;
    onDutyDriver: {
        employeeCode: string;
        name: string;
        id: number;
    };
    offDutyDriver: {
        employeeCode: string;
        name: string;
        id: number;
    };
    assetTruckId: number;
    incidentReportDocumentId: number | null;
    driverStatement: string;
    safetyManagerStatement: string;
    onDutyDriverId?: number;
    isPreventable: boolean;
    isFatalities: boolean;
    isDriverFatality: boolean;
    fatalityNote: string;
    isInjuries: boolean;
    isDriverInjury: boolean;
    injuryNote: string;
    isPhysicalDamage: boolean;
    physicalDamageNote: string;
    towingBillDocumentId: number | null;
    isHazmat: boolean;
    hazmatNote: string;
    cleanupBillDocumentId: number | null;
    isInsurance: boolean;
    citations: [];
    offDutyDriverId: number;
    removalReason: string;
    lastModifiedById: string;
    lastModifiedDate: string | null
    incidentCode: string
}

export interface IncidentsBasicInformation {
    id: number;
    incidentStatus: IncidentStatusEnum;
    reportNumber: string;
    reportDate: Date | string | null;
    onDutyDriverId: number;
    offDutyDriverId: number;
    removalReason: string;
    location: string;
    driverStatement: string;
    safetyManagerStatement: string;
    incidentReportDocumentId: number;
    assetTruckId: number;
    assetTrailerIds: number[];
    citations: CitationIncidents[] | [];
}

export interface IncidentDetails {
    id: number;
    isPreventable: boolean;
    isFatalities: boolean;
    isDriverFatality: boolean;
    fatalityNote: string;
    isInjuries: boolean;
    isDriverInjury: boolean;
    injuryNote: string;
    isPhysicalDamage: boolean;
    physicalDamageNote: string;
    towingBillDocumentId: number | null;
    isHazmat: boolean;
    hazmatNote: string;
    cleanupBillDocumentId: number | null;
    isInsurance: boolean;
}

export interface IncidentPhoto {
    incidentId: number;
    documentId: number;
    id: number;
}

export interface AddIncidentPhoto {
    documentId: number;
    id: number;
}

export interface IncidentDocuments {
    id: number;
    documentId: number;
    documentType: string;
    description: string;
    issuer: string;
    issueDate: Date | string;
    expiryDate: Date | string;
}

export interface IncidentsReport {
    id: number;
    coverPage: string | null;
    incidentReportDocumentId: number;
    towingBillDocumentId: number;
    cleanupBillDocumentId: number;
    citations:
        | {
              citationDocumentId: number;
              id: number;
          }[]
        | [];
    documents:
        | {
              documentId: number;
              id: number;
          }[]
        | [];
        incidentPhotos:
        | {
              documentId: number;
              id: number;
          }[]
        | [];
        insuranceClaims: []
}

export interface IncidentAUX {
    incidentStatus: IncidentStatusEnum;
    reportNumber: string;
    reportDate: string | null;
    onDutyDriverId: number;
    onDutyDriver: string;
    assetTruck: string;
    id: number;
}

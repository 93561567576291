import { Subject, BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ImageViewer, GetDataOptions } from './../../../shared/model/shared.model';
import {
    Incidents,
    IncidentsBasicInformation,
    IncidentDetails,
    IncidentPhoto,
    AddIncidentPhoto,
    IncidentDocuments,
    IncidentStatusEnum,
    IncidentsReport,
    IncidentAUX,
} from './../models/incident.model';
import {
    ApiResponse,
    ApiResponseAny,
    PagingData,
} from './../../../core/models/api-response.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


const APIURL = `${environment.apiUrl}/cmp/`;
@Injectable({
    providedIn: 'root',
})
export class IncidentsApiService {
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'json',
        }),
        responseType: 'json' as 'json',
    };
    incidentStatus$ = new BehaviorSubject<IncidentStatusEnum>(
        IncidentStatusEnum.Open
    );
    constructor(private http: HttpClient) { }

    async getIncidentsList(
        options: GetDataOptions
    ): Promise<ApiResponse<PagingData<Incidents>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<Incidents>>>(
                APIURL +
                `Incident/fplist?searchString=${options.searchString}` +
                `&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getIncidentDetails(id: number): Promise<ApiResponse<Incidents>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<Incidents>>(APIURL + `Incident/${id}`));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getDocumentsReport(
        id: number
    ): Promise<ApiResponse<IncidentsReport>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<IncidentsReport>>(
                APIURL + `Incident/${id}/Document/Active`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async deleteIncident(incidentId: number): Promise<ApiResponse<Incidents>> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponse<Incidents>>(APIURL + `Incident/${incidentId}`));
        return response;
    }

    async addIncidentBasicInformation(
        body: IncidentsBasicInformation
    ): Promise<ApiResponse<Incidents>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<Incidents>>(
                APIURL + `Incident`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async updateIncidentkBasicInformation(
        incidentId: number,
        body: IncidentsBasicInformation
    ): Promise<ApiResponse<Incidents>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<Incidents>>(
                APIURL + `Incident/${incidentId}/PersonalDetails`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async updateIncidentDetails(
        incidentId: number,
        body: IncidentDetails
    ): Promise<ApiResponse<Incidents>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<Incidents>>(
                APIURL + `Incident/${incidentId}/ReportDetails`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getIncidentsPhotos(
        incidentId: number,
        options: GetDataOptions
    ): Promise<ApiResponse<PagingData<IncidentPhoto>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<IncidentPhoto>>>(
                APIURL +
                `Incident/${incidentId}/Photo/FpList?searchString=${options.searchString}` +
                `&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getAllIncidentsAux(
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<IncidentAUX>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<IncidentAUX>>>(
                APIURL +
                `IncidentAux/FpList?pageSize=${options?.pageSize
                }&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : 'reportDate desc'
                }&searchString=${options?.searchString}&searchById=${options?.searchById
                }`,
                this.requestOptions
            ));
        return response;
    }

    async addIncidentPhoto(
        incidentId: number,
        body: AddIncidentPhoto
    ): Promise<ApiResponse<AddIncidentPhoto>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<AddIncidentPhoto>>(
                APIURL + `Incident/${incidentId}/Photo`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }
    /////////////////////////////*****  Other Documents  *****////////////////////////////////////

    async getDocumentsList(
        incidentId: number,
        options: GetDataOptions
    ): Promise<ApiResponse<PagingData<IncidentDocuments>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<IncidentDocuments>>>(
                APIURL +
                `Incident/${incidentId}/Document/FpList?searchString=${options.searchString}` +
                `&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getDocumentDetails(
        incidentId: number,
        documentId: number
    ): Promise<ApiResponse<IncidentDocuments>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<IncidentDocuments>>(
                APIURL + `Incident/${incidentId}/Document/${documentId}`
            ));
        if (response.success) {
            return response;
        } else
            return {
                success: false,
                data: null,
            };
    }

    async addDocument(
        incidentId: number,
        body: IncidentDocuments
    ): Promise<ApiResponse<IncidentDocuments>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<IncidentDocuments>>(
                APIURL + `Incident/${incidentId}/Document`,
                body
            ));
        return response;
    }

    async editDocument(
        incidentId: number,
        body: IncidentDocuments,
        documentId: number
    ): Promise<ApiResponse<IncidentDocuments>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<IncidentDocuments>>(
                APIURL + `Incident/${incidentId}/Document/${documentId}`,
                body
            ));
        return response;
    }

    async deleteDocument(
        incidentId: number,
        documentId: number
    ): Promise<ApiResponse<IncidentDocuments>> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponse<IncidentDocuments>>(
                APIURL + `Incident/${incidentId}/Document/${documentId}`
            ));
        return response;
    }

    /****************************** **********************************/

    async deletePhoto(
        incidentId: number,
        body: []
    ): Promise<ApiResponse<IncidentDocuments>> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        let response = await lastValueFrom(this.http
            .delete<ApiResponse<IncidentDocuments>>(
                APIURL + `Incident/${incidentId}/Photo`,
                options
            ));
        return response;
    }

    printIncidentReport(
        incidentId: number,
        body: {}): Observable<Blob | any> {
        let url = APIURL + `Incident/${incidentId}/PrintIncidentReport`;
        return this.http.put(url, body, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    async printFile(
        incidentId: number,
        body: {}
    ) {
        try {
            let response = await lastValueFrom(this.printIncidentReport(incidentId, body));
            var newBlob = new Blob([response.body], {
                type: response.body.type,
            });
            const data = window.URL.createObjectURL(newBlob);
            let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = data;
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow?.print();
                }, 1);
            };
            return {
                sucsses: true
            }
        } catch (err) {
            // this.shared.alert(
            //     'There was an error printing the file, please try again',
            //     AlertTypeEnum.Danger
            // );
            return {
                sucsses: false
            }
        } finally {
        }
    }
}
